


































































































































































































































































































































































/* eslint-disable  */
import Vue from "vue";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default Vue.extend({
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "btn-add-coute",
  data() {
    return {
      admin: false,
      panel: null,
      dialog: false,
      now: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogFinish: false,
      loadingCreate: false,
      dialogtype: "",
      type: null,
      selected: null,
      rules,
      indexEdit: null,
      loadingNom: false,
      expirepick: false,
      doctorUuid: "",
      surgigalUuid: "",
      price: 0,
      discount: 0,
      items: [],
      itemquote: null,
    };
  },
  watch: {
    items(val) {
      if (val != undefined && val != null && val != []) {
        (this as any).panel = 0;
      }
    },
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail", "procedureCreated"]),
    ...mapState("crmEmployeeModule", ["doctors", "agents", "coordinators"]),
    ...mapGetters(["nomAccesory", "isAdmin", "isSuper", "getCoordinatorN"]),
    ...mapState("crmSettingsModule", ["treatments", "surgerys", "accessories"]),
    types() {
      return ["Treatments", "Surgerys", this.nomAccesory];
    },

    noSend() {
      if (
        (this as any).doctorUuid == "" ||
        ((this as any).admin && (this as any).surgigalUuid == "")
      ) {
        return true;
      }
      return false;
    },

    subtypes() {
      switch ((this as any).type) {
        case "Treatments":
          return this.treatments;
        case "Surgerys":
          return this.surgerys;
        case this.nomAccesory:
          return this.accessories;
      }
      return [];
    },
    itemSelected() {
      const nom = (this as any).subtypes.filter(
        (prossub: any) => prossub.id == (this as any).selected
      );
      const firstObj = nom.length > 0 ? nom[0] : null;
      return firstObj != null ? firstObj : null;
    },
    minPrice() {
      const nom = (this as any).subtypes.filter(
        (prossub: any) => prossub.id == (this as any).selected
      );
      const firstObj = nom.length > 0 ? nom[0] : null;

      return firstObj != null ? firstObj.minValue : 0;
    },
    maxPrice() {
      const nom = (this as any).subtypes.filter(
        (prossub: any) => prossub.id == (this as any).selected
      );

      const firstObj = nom.length > 0 ? nom[0] : null;
      return firstObj != null ? firstObj.maxValue : 0;
    },
    validCost() {
      if ((this as any).price == null || (this as any).price == undefined) {
        return false;
      }
      if (
        Number((this as any).price) < Number((this as any).minPrice) ||
        Number((this as any).price) > Number((this as any).maxPrice)
      ) {
        return false;
      }
      return true;
    },
    validDiscost() {
      if (Number((this as any).discount) > Number((this as any).maxDiscount)) {
        return false;
      }
      return true;
    },
    maxDiscount() {
      return Number((this as any).price) - Number((this as any).minPrice);
    },
    showItems() {
      return (this as any).items;
    },
  },

  methods: {
    ...mapActions("crmSettingsModule", ["actListAllNomenclator"]),
    ...mapActions("crmEmployeeModule", [
      "actGetPatientDoctors",
      "actGetCoordinators",
    ]),
    ...mapActions("crmMedicFormModule", [
      "actCreateQuote",
      "actCreateprocedure",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutProcedure"]),
    async loadallNom() {
      (this as any).loadingNom = true;
      await (this as any).actListAllNomenclator();
      (this as any).loadingNom = false;
    },

    dialogOpen(typedialog: string) {
      (this as any).dialogFinish = true;
      (this as any).dialogtype = typedialog;
    },

    addQuote() {
      let disc = 0;
      const procedures = (this as any).items.map((it: any) => {
        disc = disc + Number(it.discount);
        return {
          id: it.iditem,
          type:
            it.type != "Surgery" && it.type != "Treatment"
              ? "Accessory"
              : it.type,
          price: Number(it.price),
        };
      });

      const expDate = new Date((this as any).date);
      let quote;
      (this as any).loadingCreate = true;
      if ((this as any).dialogtype == "send") {
        quote = {
          quote_type: (this as any).itemquote.quote_type,
          nomId: (this as any).itemquote.nomId,
          patientUuid: this.patientDetail.uuid,
          procedures: procedures,
          discount: Number(disc),
          doctorUuid: (this as any).doctorUuid,
          expiredDate: expDate.toISOString(),
          aproved: false,
          status: "SENT",
        };
      } else {
        quote = {
          quote_type: (this as any).itemquote.quote_type,
          nomId: (this as any).itemquote.nomId,
          patientUuid: this.patientDetail.uuid,
          doctorUuid: (this as any).doctorUuid,
          procedures: procedures,
          discount: Number(disc),
          expiredDate: expDate.toISOString(),
          aproved: true,
          status: "CREATED",
        };
      }

      quote = { ...quote, coordinatorUuid: (this as any).surgigalUuid };

      (this as any).actCreateQuote(quote).then((res: any) => {
        if (res != undefined) {
          notifySuccess(
            `Quote has been create and ${
              (this as any).dialogtype == "send" ? "Sended" : "Approve"
            } `
          );

          if (res.aproved) {
            const body = {
              quoteUuid: res.id,
            };
            (this as any).createProcedure(body);
          } else {
            this.$emit("created");
            (this as any).closefinalStep();
            (this as any).cancel();
            (this as any).reset();

            (this as any).loadingCreate = false;
          }
        } else {
          (this as any).loadingCreate = false;
        }
      });
    },

    addItem() {
      let typeTemp = "";
      if ((this as any).type == "Accessories") {
        typeTemp = "Accessory";
      } else {
        typeTemp = (this as any).type.substring(
          0,
          (this as any).type.length - 1
        );
      }

      const ItemTemp = {
        iditem:
          (this as any).itemSelected != null
            ? (this as any).itemSelected.id
            : null,
        type: typeTemp,
        price: (this as any).price,
        name:
          (this as any).itemSelected != null
            ? (this as any).itemSelected.name
            : "",
        discount: (this as any).discount ? (this as any).discount : 0,
      };

      if (
        (this as any).itemquote == null &&
        (ItemTemp.type == "Treatment" || ItemTemp.type == "Surgery")
      ) {
        (this as any).itemquote = {
          quote_type: ItemTemp.type,
          nomId: ItemTemp.iditem,
          price: ItemTemp.price,
        };
        (this as any).items.push(ItemTemp);
      } else {
        (this as any).items.push(ItemTemp);
      }
      (this as any).discount = 0;
      (this as any).reset();
    },
    deleteItem(index: number) {
      (this as any).items.splice(index, 1);
    },
    toeditItem(item: any, index: number) {
      (this as any).indexEdit = index;
      let typTem;
      if (item.type == "Treatment") {
        typTem = "Treatments";
      } else if (item.type == "Surgery") {
        typTem = "Surgerys";
      } else {
        typTem = this.nomAccesory;
      }
      (this as any).type = typTem;
      (this as any).selected = item.iditem;
      (this as any).price = item.price;
    },
    editItem() {
      let typeTemp = "";
      if ((this as any).type == "Accessories") {
        typeTemp = "Accessory";
      } else {
        typeTemp = (this as any).type.substring(
          0,
          (this as any).type.length - 1
        );
      }

      const ItemTemp = {
        iditem:
          (this as any).itemSelected != null
            ? (this as any).itemSelected.id
            : null,
        type: typeTemp,
        price: (this as any).price,
        name:
          (this as any).itemSelected != null
            ? (this as any).itemSelected.name
            : "",
        discount: (this as any).discount,
      };

      (this as any).items[(this as any).indexEdit] = ItemTemp;

      (this as any).reset();
    },

    //****Create Procedure */
    createProcedure(body: any) {
      (this as any)
        .actCreateprocedure(body)
        .then(() => {
          this.$emit("created");
          notifySuccess("Procedure created successfully");
          (this as any).loadingCreate = false;
          (this as any).closefinalStep();
          (this as any).reset();
          (this as any).cancel();
        })
        .catch((error: any) => {
          (this as any).loadingCreate = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    closefinalStep() {
      (this as any).date = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      (this as any).dialogFinish = false;
      (this as any).dialogtype = "";
      (this as any).doctorUuid = "";
      (this as any).discount = 0;
    },
    cancel() {
      (this as any).dialog = false;
      (this as any).items = [];
      (this as any).itemquote = null;
      (this as any).panel = null;

      (this as any).reset();
    },
    reset() {
      (this as any).type = null;
      (this as any).selected = null;
      (this as any).price = 0;
      (this as any).indexEdit = null;
    },

    /**botones */
    clickAmount() {
      if ((this as any).price == 0) {
        (this as any).price = "";
      }
    },
    clickdisco() {
      if ((this as any).discount == 0) {
        (this as any).discount = "";
      }
    },

    async getDoctors() {
      await (this as any).actGetPatientDoctors();
    },

    async getCoordinators() {
      await (this as any).actGetCoordinators();
    },
  },
  async mounted() {
    (this as any).loadallNom();
    await (this as any).getCoordinators();
    await (this as any).getDoctors();
    if (this.isAdmin || this.isSuper) {
      (this as any).admin = true;
    } else {
      (this as any).admin = false;
    }
  },
});
