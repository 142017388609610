<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex align-center justify-center flex-column pa-4">
    <v-container fluid>
      <v-row align="center" justify="center" class="mx-4">
        <v-col cols="12" sm="6" md="6">
          <v-switch
            class="text-uppercase"
            :color="getColor('pt')"
            inset
            v-model="pt"
            label="Pt"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('chem')"
            inset
            v-model="chem"
            label="Chem"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('pt')"
            inset
            v-model="pt_ptt_inr"
            label="Pt_ptt_inr"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('hiv')"
            inset
            v-model="hiv"
            label="Hiv"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('hcg')"
            inset
            v-model="hcg"
            label="Hcg"
          ></v-switch>

          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-switch
            class="text-uppercase"
            :color="getColor('a1c')"
            inset
            v-model="a1c"
            label="A1c"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('ua')"
            inset
            v-model="ua"
            label="Ua"
          ></v-switch>
          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('mc')"
            inset
            v-model="mc"
            label="Mc"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('ekg')"
            inset
            v-model="ekg"
            label="Ekg"
          ></v-switch>

          <v-divider></v-divider>
          <v-switch
            class="text-uppercase"
            :color="getColor('man')"
            inset
            v-model="man_us"
            label="Man_us"
          ></v-switch>

          <v-divider></v-divider>
        </v-col>
      </v-row>
      <div class="d-flex justify-end ma-4">
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="info"
          @click="updateLabs"
          >Update</v-btn
        >
      </div>
    </v-container>
  </div>
</template>
<script>
import { utilMixin } from "@/utils/mixins";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
export default {
  name: "patient-labs",
  mixins: [utilMixin],
  props: {
    patient: Object,
  },
  data() {
    return {
      loading: false,
      pt: true,
      chem: true,
      pt_ptt_inr: true,
      hiv: false,
      hcg: true,
      a1c: true,
      ua: true,
      mc: true,
      ekg: true,
      man_us: true,
    };
  },
  methods: {
    updateLabs() {
      const labsCkeck = {
        pt: this.pt,
        chem: this.chem,
        pt_ptt_inr: this.pt_ptt_inr,
        hiv: this.hiv,
        hcg: this.hcg,
        a1c: this.a1c,
        ua: this.ua,
        mc: this.mc,
        ekg: this.ekg,
        man_us: this.man_us,
      };
      this.loading = true;
      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, {
          labsCkeck: labsCkeck,
        })
        .then(() => {
          this.loading = false;
          this.$emit("updatelabs");
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    if (this.patient != undefined) {
      this.pt = this.patient.labsCkeck.pt;
      this.chem = this.patient.labsCkeck.chem;
      this.pt_ptt_inr = this.patient.labsCkeck.pt_ptt_inr;
      this.hiv = this.patient.labsCkeck.hiv;
      this.hcg = this.patient.labsCkeck.hcg;
      this.a1c = this.patient.labsCkeck.a1c;
      this.ua = this.patient.labsCkeck.ua;
      this.mc = this.patient.labsCkeck.mc;
      this.ekg = this.patient.labsCkeck.ekg;
      this.man_us = this.patient.labsCkeck.man_us;
    }
  },
};
</script>
<style lang=""></style>
